import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Container, Typography, Button } from '@material-ui/core';

import Image from '../components/image';
import Reader from '../components/reader';

import CalendarIcon from '../assets/icons/calendar.svg';
import LightIcon from '../assets/icons/light.svg';
import ArrowIcon from '../assets/icons/arrow.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#e9edee',
    padding: theme.spacing(13, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0),
    },
  },
  mainTitleSection: {
    paddingBottom: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftTitleSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  iconWrapper: {
    paddingBottom: 5,
  },
  textWrapper: {
    margin: theme.spacing(0, 2.5),
    maxHeight: 430,
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
    },
  },
  titleText: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 700,
  },
  paragraphText: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 600,
  },
  fade: {
    position: 'absolute',
    bottom: 0,
    display: 'block',
    width: '100%',
    height: '50px',
    backgroundImage: 'linear-gradient(to bottom,rgba(255, 255, 255, 0),rgb(233 237 238)100%)',
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  button1: {
    width: 151,
    height: 40,
    boxShadow: '0 3px 10px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: 20,
    backgroundImage: 'linear-gradient(180deg, #369eff 0%, #007ff5 100%)',
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: 700,
    zIndex: 100,
    border: 'none',
  },
  button2: {
    width: 151,
    height: 40,
    boxShadow: '0 3px 10px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: 20,
    backgroundImage: 'linear-gradient(180deg, #00dc41 0%, #00b134 100%)',
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: 700,
    zIndex: 100,
    border: 'none',
  },
  imageWrapper: {
    paddingLeft: theme.spacing(9),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '0',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: theme.spacing(16),
    }
  }
}));

export default function () {
  const classes = useStyles();
  const [open, setOpen] = useState({ reader1: false, reader2: false });

  const handelOpen = event => {
    setOpen({ ...open, [event.currentTarget.id]: true });
  };

  const handleClose = () => {
    setOpen({ reader1: false, reader2: false });
  };

  useEffect(() => {
    if (open.reader1 || open.reader2) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  return (
    <>
      <section className={classes.root}>
        <Container>
          <div className={classes.mainTitleSection}>
            <Typography variant="h2">
              Inside the App.
            </Typography>
            <Typography variant="h4" style={{
              maxWidth: '675px',
              textAlign: 'center',
              marginTop: '8px'
            }}>
              Don't just take our word for it. Here are two examples of the services we provide on a daily basis.
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className={classes.leftTitleSection}>
                <div className={classes.iconWrapper}>
                  <CalendarIcon />
                </div>
                <Typography variant="h3" gutterBottom>
                  Daily Briefing
                </Typography>
              </div>
              <div>
                <div className={classes.imageWrapper}>
                <Image name="infoImage1" />
                </div>
                <div className={classes.textWrapper}>
                  <Typography variant="subtitle2" gutterBottom>
                    September 29th – 9:23 AM EDT
                  </Typography>
                  <Typography variant="caption" paragraph>
                    “A billion here, a billion there, and pretty soon you're talking real money.” – JP Morgan 1912
                  </Typography>
                  <Typography className={classes.titleText} paragraph>
                    Market Summary
                  </Typography>
                  <Typography className={classes.paragraphText} paragraph>
                    - Stocks had another strong session yesterday, closing 1.61% higher off strong economic data numbers
                    domestically and globally, with progress in stimulus discussions.
                  </Typography>
                  <Typography className={classes.paragraphText} paragraph>
                    - The $25 billion airline bailout expires on Thursday. Airlines are expected to begin furloughing
                    workers and cutting routes to cut the cash burn rate. Airline revenue is down over 65% compared to
                    last year.
                  </Typography>
                  <Typography className={classes.paragraphText} paragraph>
                    - House Democrats released a fresh $2.2 trillion stimulus proposal, which is an attempt to
                    compromise with Senate Republicans as the original House bill was $3.4 trillion. House Speaker Nancy
                    Pelosi and Treasury Secretary Steven Mnuchin held talks last evening and will be speaking again this
                    morning.
                  </Typography>
                  <div className={classes.buttonWrapper}>
                    <Button
                      id="reader1"
                      variant="outlined"
                      endIcon={<ArrowIcon />}
                      className={classes.button1}
                      onClick={handelOpen}
                    >
                      Read More
                    </Button>
                  </div>

                  <div className={classes.fade} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.leftTitleSection}>
                <div className={classes.iconWrapper}>
                  <LightIcon />
                </div>
                <Typography variant="h3" gutterBottom>
                  Trade Call
                </Typography>
              </div>
              <div>
                <div className={classes.imageWrapper}>
                <Image name="infoImage2" />
                </div>
                <div className={classes.textWrapper}>
                  <Typography variant="subtitle2" gutterBottom>
                    October 6th – 9:40 AM EDT
                  </Typography>
                  <Typography variant="caption" component="div" paragraph>
                    <Image name="infoLogo" />
                  </Typography>
                  <Typography className={classes.paragraphText} paragraph>
                    Kensington is currently in the process of buying QuantumScape, which, according to many in the
                    electric vehicle industry, is building the best battery on the market. This is what former the Tesla
                    Chief Technology Officer and current QuantumScape board member JB Straubel had to say:
                    “QuantumScape’s solid-state anode-less design represents the most elegant architecture I’ve seen for
                    a lithium-based battery system, and the company has an opportunity to redefine the battery
                    landscape.” They are backed by Bill Gates, Chinese automaker SAIC, and most importantly, Volkswagen,
                    who is also in a joint venture with the firm. Volkswagen produces 11 million cars a year and is
                    going all-in on electric vehicles. Once the merger is complete, the new company at the current KCAC
                    share price will have a valuation of around $4.8 billion. With over $1 billion in cash on the
                    balance sheet once the transaction is complete, the company has enough cash to get to the production
                    and profitability stage. If a QuantumScape battery is in just five million new vehicles a year
                    (current annual production is 90 million) by 2035, it will equate to an estimated $25 billion in
                    sales and $2 billion in profits, which would equal a valuation north of $30 billion.
                  </Typography>

                  <div className={classes.buttonWrapper}>
                    <Button
                      id="reader2"
                      variant="outlined"
                      endIcon={<ArrowIcon />}
                      className={classes.button2}
                      onClick={handelOpen}
                    >
                      Read More
                    </Button>
                  </div>

                  <div className={classes.fade} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Reader open={open.reader1} close={handleClose} icon={<CalendarIcon />} title="Daily Briefing">
        <Typography className={classes.paragraphText} paragraph>
          <i>“A billion here, a billion there, and pretty soon you're talking real money.”</i> – JP Morgan 1912
        </Typography>
        <Typography className={classes.titleText} paragraph>
          Market Summary
        </Typography>
        <Typography className={classes.paragraphText} paragraph>
          - Stocks had another strong session yesterday, closing 1.61% higher off strong economic data numbers
          domestically and globally, with progress in stimulus discussions.
        </Typography>
        <Typography className={classes.paragraphText} paragraph>
          - The $25 billion airline bailout expires on Thursday. Airlines are expected to begin furloughing workers and
          cutting routes to cut the cash burn rate. Airline revenue is down over 65% compared to last year.
        </Typography>
        <Typography className={classes.paragraphText} paragraph>
          - House Democrats released a fresh $2.2 trillion stimulus proposal, which is an attempt to compromise with
          Senate Republicans as the original House bill was $3.4 trillion. House Speaker Nancy Pelosi and Treasury
          Secretary Steven Mnuchin held talks last evening and will be speaking again this morning.
        </Typography>
        <Typography className={classes.paragraphText} paragraph>
          - The US Center for Disease Control (CDC) has released an update on their research on the fatality rate
          associated with COVID-19 with higher survival rates than previous data. Under 50 years old survival rate is
          99.98%, the 50- 69 age level is 99.5%, and over 70 years old is 94.6%.
        </Typography>
        <Typography className={classes.titleText} paragraph>
          Insights and Observations
        </Typography>
        <Typography className={classes.paragraphText} paragraph>
          - Whenever there is a crisis, it always brings some temporary changes but also permanent changes. Although
          internet commerce was steadily gaining market share, there has been a large acceleration in the share of
          online shopping since the COVID crisis. Many consumers have increased their online shopping, but more
          impressive is the number of new shoppers embracing online commerce. Once a consumer begins shopping online,
          they often increase this activity. This acceleration will negatively affect commercial real estate, brick and
          mortar retailers, and positively affects eCommerce service providers.
        </Typography>
        <Typography className={classes.paragraphText} paragraph>
          <b>Chart of the Day: Acceleration of the Inevitable</b>
        </Typography>
        <Image name="readerImage" />
      </Reader>
      <Reader open={open.reader2} close={handleClose} icon={<LightIcon />} title="Trade Call">
        <Typography className={classes.paragraphText} paragraph>
          Kensington is currently in the process of buying QuantumScape, which, according to many in the electric
          vehicle industry, is building the best battery on the market. This is what former the Tesla Chief Technology
          Officer and current QuantumScape board member JB Straubel had to say: “QuantumScape’s solid-state anode-less
          design represents the most elegant architecture I’ve seen for a lithium-based battery system, and the company
          has an opportunity to redefine the battery landscape.” They are backed by Bill Gates, Chinese automaker SAIC,
          and most importantly, Volkswagen, who is also in a joint venture with the firm. Volkswagen produces 11 million
          cars a year and is going all-in on electric vehicles. Once the merger is complete, the new company at the
          current KCAC share price will have a valuation of around $4.8 billion. With over $1 billion in cash on the
          balance sheet once the transaction is complete, the company has enough cash to get to the production and
          profitability stage. If a QuantumScape battery is in just five million new vehicles a year (current annual
          production is 90 million) by 2035, it will equate to an estimated $25 billion in sales and $2 billion in
          profits, which would equal a valuation north of $30 billion.
        </Typography>
      </Reader>
    </>
  );
}
