import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = props => {
  const { name } = props;

  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "image-1.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      infoImage1: file(relativePath: { eq: "info-image-1.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      infoImage2: file(relativePath: { eq: "info-image-2.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      infoLogo: file(relativePath: { eq: "info-logo.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 168) {
            ...GatsbyImageSharpFluid_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      readerImage: file(relativePath: { eq: "reader-image.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 570) {
            ...GatsbyImageSharpFluid_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return <Img fluid={data[name].childImageSharp.fluid} fadeIn={false} {...props} />;
};

export default Image;
