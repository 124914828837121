import React from 'react';
import { makeStyles, Typography, Container, Grid, Button } from '@material-ui/core';

import Image from '../assets/images/image-2.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f0f5f6',
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
  textContainer: {
    [theme.breakpoints.down('sm')]: {
    },
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  item1: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  item2: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  imageWrapper: {
    display: 'flex',
    marginTop: 40
  },
  image: {
    marginLeft: 'auto',
    height: '100%',
  },
  button: {
    backgroundImage: 'linear-gradient(180deg, #369EFF 0%, #007FF5 100%)',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.15)',
    maxWidth: 300,
    height: 55,
    fontSize: 19,
    borderRadius: 8,
    marginTop: theme.spacing(3),
  },
}));

export default function SectionD() {
  const classes = useStyles();

  return (
    <section id="about" className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={5} className={classes.item1}>
            <div className={classes.textContainer}>
              <Typography variant="h2" style={{
                marginBottom: 25
              }}>About Us.</Typography>
              <Typography variant="h4">
                IntelliTrade was founded with the idea to provide trusted investment ideas in a simplified format to guide you on a
                successful investing path. Many investors today are provided poor advice which
                leads to bad investment decisions, and coupled with improper risk controls, it can quickly evaporate
                hard-earned money from your account. We have decades of successful research and
                trading experience and we want to bring the same experience and success to your portfolio.
              </Typography>
              <Typography variant="h4">
                We are committed to offering true transparency in our trade recommendations and a promise to no front
                running trades.
              </Typography>
              <Button fullWidth className={classes.button} href="https://user.intellitrade.app/signup">
                Get started for just $1
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={7} className={classes.item2}>
            <div className={classes.imageWrapper}>
              <Image className={classes.image} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
