import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Container, Grid, Hidden } from '@material-ui/core';

import PriceIcon from '../assets/icons/price.svg';
import RefundIcon from '../assets/icons/refund.svg';
import OperatorIcon from '../assets/icons/operator.svg';
import DownloadIcon from '../assets/icons/download.svg';
import PasswordIcon from '../assets/icons/password.svg';

import Card from '../components/card';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#e9edee',
    padding: theme.spacing(13, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0),
    },
  },
  textSection: {
    marginBottom: theme.spacing(4),
  },
}));

export default function SectionE() {
  const classes = useStyles();

  return (
    <section id="faq" className={classes.root}>
      <Container>
        <div className={classes.textSection}>
          <Typography variant="h2" align="center">
            FAQ.
          </Typography>
          <Typography variant="h4" align="center">
            Have questions? We've got answers.
          </Typography>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card>
              <PriceIcon className="icon" />
              <Typography variant="h4" align="center">
                What is the pricing of IntelliTrade?
              </Typography>
              <Typography variant="h6" align="center">
                IntelliTrade costs $1 for the first 30 days and then $9.99 per month after that. You can manage your subscription via the <a href="https://user.intellitrade.app">dashboard</a>.              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <RefundIcon className="icon" />
              <Typography variant="h4" align="center">
                Is my purchase refundable?
              </Typography>
              <Typography variant="h6" align="center">
                Monthly subscription charges are not refundable but can be <a href="https://user.intellitrade.app">cancelled</a> at any time.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <OperatorIcon className="icon" />
              <Typography variant="h4" align="center">
                How can I contact the support team?
              </Typography>
              <Typography variant="h6" align="center">
                The IntelliTrade support team can be contacted at <a href="mailto:support@intellitrade.app">support@intellitrade.app</a> for billing errors or software bugs.
              </Typography>
            </Card>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={2} />
          </Hidden>
          <Grid item xs={12} md={4}>
            <Card>
              <DownloadIcon className="icon" />
              <Typography variant="h4" align="center">
                Where can I download IntelliTrade?
              </Typography>
              <Typography variant="h6" align="center">
                You can download the IntelliTrade app on both the <a href="https://apps.apple.com/us/app/id1522378612">Apple App Store</a> and <a href="https://play.google.com/store/apps/details?id=app.intellitrade.android">Google Play Store</a>
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <PasswordIcon className="icon" />
              <Typography variant="h4" align="center">
                Where can I change my password?
              </Typography>
              <Typography variant="h6" align="center">
                You can reset your password by clicking <a href="https://user.intellitrade.app/reset">here</a>
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
