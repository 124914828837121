import React from 'react';
import { makeStyles, useMediaQuery, Typography, Container, Grid } from '@material-ui/core';

import ContractIcon from '../assets/icons/contract.svg';
import IdeaIcon from '../assets/icons/idea.svg';
import ChartIcon from '../assets/icons/chart.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f0f5f6',
    padding: theme.spacing(13, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0),
    },
  },
  textContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default function SectionB() {
  const classes = useStyles();
  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <section id="features" className={classes.root}>
      <Container>
        <Typography variant="h2">What We Offer.</Typography>
        <Grid container spacing={matches ? 3 : 7} className={classes.textContainer}>
        <Grid item xs={12} md={4}>
            <ChartIcon />
            <Typography variant="h3" gutterBottom>
              Trade Signals
            </Typography>
            <Typography variant="h4">
            The team at IntelliTrade works hard to bring customers thoroughly researched investment ideas. We provide signals on when to buy and exit trades in multiple markets, including stocks, options, and commodities. Trade signals will be accompanied by a brief report providing only the information you need to know. And unlike other providers, We offer transparency in all of our recommendations and a commitment to no front running our trade signals.            
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <ContractIcon />
            <Typography variant="h3" gutterBottom>
              Daily Briefing
            </Typography>
            <Typography variant="h4">
            We dive into news and information from reliable sources to bring you the latest and most important market knowledge with no time-consuming fillers. The daily briefing is delivered every weekday before the market opens and provides statistics from the previous day, news you need to know, what to look for in the current day, and the current IntelliTrade investment positions. All in the same time it takes to drink a cup of coffee.           
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <IdeaIcon />
            <Typography variant="h3" gutterBottom>
            Experienced Research Team
            </Typography>
            <Typography variant="h4">
            The research team at IntelliTrade does not jump on trendy stocks, throw darts at a board, or pass on tips from chat rooms. We follow strict research and risk management protocols to not only tell you when to enter a trade, but when to exit as well. Let us do the hard work of diving into complicated company reports, accounting statements, and technical data, interpreting this information and translating it into simplified, actionable trade ideas.            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
