import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, Backdrop, Container, Typography, Button, ClickAwayListener } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    backdropFilter: 'blur(10px)',
  },
  container: {
    position: 'relative',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    height: '100%',
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  iconWrapper: {
    paddingBottom: 5,
  },
  button: {
    width: 143,
    height: 40,
    boxShadow: '0 3px 10px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: 20,
    backgroundImage: 'linear-gradient(180deg, #ff5d96 0%, #ff005a 100%)',
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: 700,
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: 50,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  reader: {
    // height: 'calc(100% - 200px)',
    paddingBottom: 100,
    paddingTop: 20,
    position: 'relative',
    marginRight: 10,
  },
  fade1: {
    position: 'absolute',
    top: 180,
    display: 'block',
    width: '100%',
    height: 25,
    backgroundImage: 'linear-gradient(to top,rgba(255, 255, 255, 0),rgb(0 0 0 /50%)100%)',
  },
  fade2: {
    position: 'absolute',
    bottom: 35,
    display: 'block',
    width: '100%',
    height: 25,
    backgroundImage: 'linear-gradient(to bottom,rgba(255, 255, 255, 0),rgb(0 0 0 /50%)100%)',
  },
  perfectScrollbar: {
    height: 'calc(100% - 180px)',
  },
}));

export default function Reader({ open, close, icon, title, children }) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open} onClick={close}>
      {/* <ClickAwayListener onClickAway={close}> */}
      <Container
        maxWidth="sm"
        className={classes.container}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className={classes.titleSection}>
          <div className={classes.iconWrapper}>{icon}</div>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
        </div>
        {/* <div className={classes.fade1} /> */}
        <PerfectScrollbar className={classes.perfectScrollbar}>
          <div className={classes.reader}>{children}</div>
        </PerfectScrollbar>
        {/* <div className={classes.fade2} /> */}
        <div className={classes.buttonWrapper}>
          <Button variant="outlined" className={classes.button} onClick={close}>
            Close Reader
          </Button>
        </div>
      </Container>
      {/* </ClickAwayListener> */}
    </Backdrop>
  );
}
