import React from 'react';
import { createMuiTheme, responsiveFontSizes, makeStyles, ThemeProvider, CssBaseline } from '@material-ui/core';

import 'typeface-muli';
import 'typeface-montserrat';
import 'react-perfect-scrollbar/dist/css/styles.css';

import SEO from '../components/seo';
import SectionA from '../features/sectionA';
import SectionB from '../features/sectionB';
import SectionC from '../features/sectionC';
import SectionD from '../features/sectionD';
import SectionE from '../features/sectionE';
import Footer from '../features/footer';

let theme = createMuiTheme({
  shadows: Array(25).fill('none'),
  palette: {
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#212324',
      secondary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Muli, Montserrat',
    h1: {
      fontSize: 45,
      fontWeight: 800,
    },
    h2: {
      fontSize: 40,
      fontWeight: 800,
    },
    h3: {
      fontSize: 24,
      fontWeight: 800,
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
      opacity: 0.6,
    },
    h5: {
      fontSize: 19,
      fontWeight: 800,
      color: 'rgba(255,255,255,0.6)',
    },
    h6: {
      fontSize: 16,
      fontWeight: 700,
      opacity: 0.6,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 700,
      opacity: 0.6,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      color: '#666c6f',
      fontFamily: 'Montserrat',
    },
    button: {
      fontSize: 20,
      fontWeight: 800,
      textTransform: 'none',
    },
    caption: {
      fontFamily: 'Montserrat',
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.2
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#ffffff',
      },
    },
    MuiLink: {
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles(themes => ({
  root: {
    padding: themes.spacing(4),
    height: '100vh',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const IndexPage = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <script async defer data-domain="intellitrade.app" src="https://plausible.io/js/plausible.js"></script>
      <SEO title="IntelliTrade" />
      <CssBaseline />
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
      <SectionE />
      <Footer />
    </ThemeProvider>
  );
};

export default IndexPage;
