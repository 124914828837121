import React from 'react';
import clsx from 'clsx';
import { makeStyles, AppBar, Toolbar, Typography, IconButton, Button, Hidden, Link } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ArrowForwardIcon from '../assets/icons/arrow.svg';
import Logo1 from '../assets/images/logo-1.svg';
import LogoMobile from '../assets/images/logo-mobile.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(6, 0),
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
    },
  },
  textContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  logoWrapper: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',

    },
  },
  buttonWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  active: {
    color: 'rgba(255,255,255,1)',
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar color="transparent" position="static">
        <Toolbar>
          <Hidden smDown>
            <div className={classes.logoWrapper}>
              <Logo1 />
            </div>
            <div className={classes.textContainer}>
              <Link href="/">
                <Typography variant="h5">
                  Home
                </Typography>
              </Link>
              <Link href="#features">
                <Typography variant="h5">Features</Typography>
              </Link>
              <Link href="#about">
                <Typography variant="h5">About Us</Typography>
              </Link>
              <Link href="#faq">
                <Typography variant="h5">FAQ</Typography>
              </Link>
            </div>
            <div className={classes.buttonWrapper}>
              <Button endIcon={<ArrowForwardIcon />} href="https://user.intellitrade.app">Sign In</Button>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.logoWrapper}>
              <LogoMobile />
            </div>
            <IconButton href="https://user.intellitrade.app" edge="start" color="inherit" aria-label="menu">
              <PermIdentityIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}
