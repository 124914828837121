import React from 'react';
import { makeStyles, AppBar, Toolbar, Typography, IconButton, Button, Container, Grid, Box } from '@material-ui/core';
import Typewriter from 'typewriter-effect';

import Header from './header';
import Image from '../components/image';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: 'linear-gradient(180deg, #007ff5 0%, #0068c9 100%)',
  },
  container: {
    padding: theme.spacing(9, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0),
    },
  },
  textContainer: {
    margin: 'auto',
    '& > *': {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  button: {
    backgroundImage: 'linear-gradient(180deg, #00dd41 0%, #00b134 100%)',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.15)',
    maxWidth: 347,
    height: 60,
    borderRadius: 8,
    marginBottom: theme.spacing(1),
  },
  image: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
  item1: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  item2: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
}));

export default function SectionA() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <Header />
        <div className={classes.container}>
          <Grid container>
            <Grid container item xs={12} md={6} className={classes.item1}>
              <div className={classes.textContainer}>
                <Typography variant="h1" color="textSecondary">
                  Actionable Stock Market Investments for <Typewriter
                    options={{
                      strings: ['the Everyday Investor.', 'New Investors.', 'Advanced Traders.', 'You.'],
                      autoStart: true,
                      loop: true,
                      pauseFor: 1000
                    }}
                  />
                </Typography>
                <Typography variant="h4" color="textSecondary">
                  We provide actionable investment ideas and insights without the complicated jargon, helping everyday investors understand the market and trade with confidence.
                </Typography>
                <div>
                  <Button fullWidth className={classes.button} href="https://user.intellitrade.app/signup">
                  Sign Up For Just $1
                  </Button>
                  <Typography color="textSecondary" variant="subtitle1">
                    30 days for just $1, then $9.99 per month
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className={classes.item2}>
              <div>
                <Image className={classes.image} name="image1" />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  );
}
