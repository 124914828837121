import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, withStyles } from '@material-ui/core';

const StyledAccordion = withStyles(theme => ({
  root: {
    border: '1px solid rgba(102, 108, 111, 0.2)',
    backgroundColor: 'rgba(102, 108, 111, 0.1)',
    '& .icon': {
      fill: '#666C6F',
    },
    '&.Mui-expanded .icon': {
      fill: '#007df1',
    },
    '&.Mui-expanded': {
      border: 'none',
      boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#ffffff',
    },
    '&.Mui-expanded .MuiTypography-h4': {
      opacity: 1,
    },
  },
  rounded: {
    '&:last-child': {
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    },
    '&:first-child': {
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
  },
}))(Accordion);

const StyledAccordionSummary = withStyles(theme => ({
  content: {
    minHeight: 125,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: 0,
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0),
    },
  },
}))(AccordionSummary);

const StyledAccordionDetails = withStyles(theme => ({}))(AccordionDetails);

export default function Card({ children }) {
  return (
    <StyledAccordion>
      <StyledAccordionSummary>
        {children[0]}
        {children[1]}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children[2]}</StyledAccordionDetails>
    </StyledAccordion>
  );
}
