/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={'IntelliTrade'}
      titleTemplate={'IntelliTrade'}
      meta={[
        {
          property: `title`,
          content: `IntelliTrade`,
        },
        {
          name: `description`,
          content: 'Intellitrade provides trustworthy investment ideas without the complicated jargon, from a proven team of professional traders, allowing everyday investors to understand and grow their portfolio with confidence.',
        },
        {
          name: `apple-itunes-app"`,
          content: 'app-id=1522378612',
        },
        {
          name: `keywords`,
          content: 'intelli, intellitrade, trade, intelligent, finance, trading, investing, app',
        },
        {
          name: `screen-orientation`,
          content: `portrait`,
        },
        {
          property: `og:title`,
          content: 'IntelliTrade',
        },
        {
          property: `og:description`,
          content: 'Intellitrade provides trustworthy investment ideas without the complicated jargon, from a proven team of professional traders, allowing everyday investors to understand and grow their portfolio with confidence.',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://intellitrade.app`,
        },
        {
          property: `og:image`,
          content: `https://user.intellitrade.app/favicon.ico`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'IntelliTrade',
        },
        {
          name: `twitter:url`,
          content: 'https://intellitrade.app',
        },
        {
          name: `twitter:site`,
          content: 'IntelliTradeApp',
        },
        {
          name: `twitter:title`,
          content: 'IntelliTrade',
        },
        {
          name: `twitter:image`,
          content: 'https://user.intellitrade.app/favicon.ico',
        },
        {
          name: `twitter:description`,
          content: 'Intellitrade provides trustworthy investment ideas without the complicated jargon, from a proven team of professional traders, allowing everyday investors to understand and grow their portfolio with confidence.',
        },
        {
          name: `apple-mobile-web-app-title`,
          content: 'IntelliTrade',
        },
        {
          name: `apple-mobile-web-app-capable`,
          content: 'yes',
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: 'default',
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
