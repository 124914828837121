import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Container, Grid, Hidden, Link } from '@material-ui/core';

import Logo from '../assets/images/logo-2.svg';
import MailIcon from '../assets/icons/mail.svg';
import TwitterIcon from '../assets/icons/twitter.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f0f5f6',
    padding: theme.spacing(6, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0),
    },
  },
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  col1: {
    flexGrow: 1,
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  col2: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

export default function SectionC() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <div className={classes.container}>
          <div className={classes.col1}>
            <div>
              <Logo />
            </div>
            <div style={{maxWidth: 850}}>
              <Typography variant="h6">IntelliTrade is a publisher of news and investment opinions for informational purposes only. Any
investment opinion of IntelliTrade through its services should not be considered personal investment
advice. IntelliTrade is not a registered investment advisor or registered broker/dealer.</Typography>
                <br></br>
                <Typography variant="h6"><a href="https://user.intellitrade.app/terms">Terms of Service</a></Typography>
              <Typography variant="h6"><a href="https://user.intellitrade.app/privacy">Privacy Policy</a></Typography>            </div>
          </div>
          <div className={classes.col2}>
            <div className={classes.iconWrapper}>
              <Link href="mailto:support@intellitrade.app">
                <MailIcon />
              </Link>
              <Link href="https://twitter.com/IntelliTradeApp">
                <TwitterIcon />
              </Link>
            </div>
            <div>
              <Typography variant="h6">© 2021 IntelliTrade. All rights reserved.</Typography>
            </div>
          </div>
        </div>
        <div className={classes.container}></div>
      </Container>
    </section>
  );
}
